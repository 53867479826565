import { resolveUnref } from './resolveUnref';
/**
 * Get the dom element of a ref of element or Vue component instance
 *
 * @param elRef
 */
export function unrefElement(elRef) {
    const plain = resolveUnref(elRef);
    return plain?.$el ?? plain;
}
