export function leftPad(str, length, fill = '0') {
    let current = String(str);
    while (current.length < length) {
        current = `${fill}${str}`;
    }
    return current;
}
export const tuple = (...args) => args;
export function toArray(val) {
    if (val === null || val === undefined) {
        return [];
    }
    return Array.isArray(val) ? val : [val];
}
export default function getDataOrAriaProps(props) {
    const retProps = {};
    Object.keys(props).forEach(key => {
        if ((key.substr(0, 5) === 'data-' ||
            key.substr(0, 5) === 'aria-' ||
            key === 'role' ||
            key === 'name') &&
            key.substr(0, 7) !== 'data-__') {
            retProps[key] = props[key];
        }
    });
    return retProps;
}
export function getValue(values, index) {
    return values ? values[index] : null;
}
export function updateValues(values, value, index) {
    const newValues = [getValue(values, 0), getValue(values, 1)];
    newValues[index] =
        typeof value === 'function' ? value(newValues[index]) : value;
    if (!newValues[0] && !newValues[1]) {
        return null;
    }
    return newValues;
}
