import canUseDom from '../../_util/canUseDom';
const MARK_KEY = `vc-util-key`;
function getMark({ mark } = {}) {
    if (mark) {
        return mark.startsWith('data-') ? mark : `data-${mark}`;
    }
    return MARK_KEY;
}
function getContainer(option) {
    if (option.attachTo) {
        return option.attachTo;
    }
    const head = document.querySelector('head');
    return head || document.body;
}
export function injectCSS(css, option = {}) {
    if (!canUseDom()) {
        return null;
    }
    const styleNode = document.createElement('style');
    if (option.csp?.nonce) {
        styleNode.nonce = option.csp?.nonce;
    }
    styleNode.innerHTML = css;
    const container = getContainer(option);
    const { firstChild } = container;
    if (option.prepend && container.prepend) {
        // Use `prepend` first
        container.prepend(styleNode);
    }
    else if (option.prepend && firstChild) {
        // Fallback to `insertBefore` like IE not support `prepend`
        container.insertBefore(styleNode, firstChild);
    }
    else {
        container.appendChild(styleNode);
    }
    return styleNode;
}
const containerCache = new Map();
function findExistNode(key, option = {}) {
    const container = getContainer(option);
    return Array.from(containerCache.get(container).children).find(node => node.tagName === 'STYLE' && node.getAttribute(getMark(option)) === key);
}
export function removeCSS(key, option = {}) {
    const existNode = findExistNode(key, option);
    existNode?.parentNode?.removeChild(existNode);
}
export function updateCSS(css, key, option = {}) {
    const container = getContainer(option);
    // Get real parent
    if (!containerCache.has(container)) {
        const placeholderStyle = injectCSS('', option);
        const { parentNode } = placeholderStyle;
        containerCache.set(container, parentNode);
        parentNode.removeChild(placeholderStyle);
    }
    const existNode = findExistNode(key, option);
    if (existNode) {
        if (option.csp?.nonce && existNode.nonce !== option.csp?.nonce) {
            existNode.nonce = option.csp?.nonce;
        }
        if (existNode.innerHTML !== css) {
            existNode.innerHTML = css;
        }
        return existNode;
    }
    const newNode = injectCSS(css, option);
    newNode.setAttribute(getMark(option), key);
    return newNode;
}
