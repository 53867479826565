import { warning } from '../../vc-util/warning';
export const INTERNAL_COL_DEFINE = 'RC_TABLE_INTERNAL_COL_DEFINE';
export function getExpandableProps(props) {
    const { expandable, ...legacyExpandableConfig } = props;
    let config;
    if (props.expandable !== undefined) {
        config = {
            ...legacyExpandableConfig,
            ...expandable,
        };
    }
    else {
        if (process.env.NODE_ENV !== 'production' &&
            [
                'indentSize',
                'expandedRowKeys',
                'defaultExpandedRowKeys',
                'defaultExpandAllRows',
                'expandedRowRender',
                'expandRowByClick',
                'expandIcon',
                'onExpand',
                'onExpandedRowsChange',
                'expandedRowClassName',
                'expandIconColumnIndex',
                'showExpandColumn',
            ].some(prop => prop in props)) {
            warning(false, 'expanded related props have been moved into `expandable`.');
        }
        config = legacyExpandableConfig;
    }
    if (config.showExpandColumn === false) {
        config.expandIconColumnIndex = -1;
    }
    return config;
}
/**
 * Returns only data- and aria- key/value pairs
 * @param {object} props
 */
export function getDataAndAriaProps(props) {
    /* eslint-disable no-param-reassign */
    return Object.keys(props).reduce((memo, key) => {
        if (key.substr(0, 5) === 'data-' || key.substr(0, 5) === 'aria-') {
            memo[key] = props[key];
        }
        return memo;
    }, {});
    /* eslint-enable */
}
