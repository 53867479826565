import { filterEmpty } from '../../_util/props-util';
import { camelize } from 'vue';
import { warning } from '../../vc-util/warning';
import TreeNode from '../TreeNode';
function isTreeSelectNode(node) {
    return node && node.type && node.type.isTreeSelectNode;
}
export function convertChildrenToData(rootNodes) {
    function dig(treeNodes = []) {
        return filterEmpty(treeNodes).map(treeNode => {
            // Filter invalidate node
            if (!isTreeSelectNode(treeNode)) {
                warning(!treeNode, 'TreeSelect/TreeSelectNode can only accept TreeSelectNode as children.');
                return null;
            }
            const slots = treeNode.children || {};
            const key = treeNode.key;
            const props = {};
            for (const [k, v] of Object.entries(treeNode.props)) {
                props[camelize(k)] = v;
            }
            const { isLeaf, checkable, selectable, disabled, disableCheckbox } = props;
            // 默认值为 undefined
            const newProps = {
                isLeaf: isLeaf || isLeaf === '' || undefined,
                checkable: checkable || checkable === '' || undefined,
                selectable: selectable || selectable === '' || undefined,
                disabled: disabled || disabled === '' || undefined,
                disableCheckbox: disableCheckbox || disableCheckbox === '' || undefined,
            };
            const slotsProps = { ...props, ...newProps };
            const { title = slots.title?.(slotsProps), switcherIcon = slots.switcherIcon?.(slotsProps), ...rest } = props;
            const children = slots.default?.();
            const dataNode = {
                ...rest,
                title,
                switcherIcon,
                key,
                isLeaf,
                ...newProps,
            };
            const parsedChildren = dig(children);
            if (parsedChildren.length) {
                dataNode.children = parsedChildren;
            }
            return dataNode;
        });
    }
    return dig(rootNodes);
}
export function fillLegacyProps(dataNode) {
    // Skip if not dataNode exist
    if (!dataNode) {
        return dataNode;
    }
    const cloneNode = { ...dataNode };
    if (!('props' in cloneNode)) {
        Object.defineProperty(cloneNode, 'props', {
            get() {
                warning(false, 'New `vc-tree-select` not support return node instance as argument anymore. Please consider to remove `props` access.');
                return cloneNode;
            },
        });
    }
    return cloneNode;
}
export function fillAdditionalInfo(extra, triggerValue, checkedValues, treeData, showPosition, fieldNames) {
    let triggerNode = null;
    let nodeList = null;
    function generateMap() {
        function dig(list, level = '0', parentIncluded = false) {
            return list
                .map((option, index) => {
                const pos = `${level}-${index}`;
                const value = option[fieldNames.value];
                const included = checkedValues.includes(value);
                const children = dig(option[fieldNames.children] || [], pos, included);
                const node = (<TreeNode {...option}>
              {children.map(child => child.node)}
            </TreeNode>);
                // Link with trigger node
                if (triggerValue === value) {
                    triggerNode = node;
                }
                if (included) {
                    const checkedNode = {
                        pos,
                        node,
                        children,
                    };
                    if (!parentIncluded) {
                        nodeList.push(checkedNode);
                    }
                    return checkedNode;
                }
                return null;
            })
                .filter(node => node);
        }
        if (!nodeList) {
            nodeList = [];
            dig(treeData);
            // Sort to keep the checked node length
            nodeList.sort(({ node: { props: { value: val1 }, }, }, { node: { props: { value: val2 }, }, }) => {
                const index1 = checkedValues.indexOf(val1);
                const index2 = checkedValues.indexOf(val2);
                return index1 - index2;
            });
        }
    }
    Object.defineProperty(extra, 'triggerNode', {
        get() {
            warning(false, '`triggerNode` is deprecated. Please consider decoupling data with node.');
            generateMap();
            return triggerNode;
        },
    });
    Object.defineProperty(extra, 'allCheckedNodes', {
        get() {
            warning(false, '`allCheckedNodes` is deprecated. Please consider decoupling data with node.');
            generateMap();
            if (showPosition) {
                return nodeList;
            }
            return nodeList.map(({ node }) => node);
        },
    });
}
