import { defineComponent, ref } from 'vue';
import generatePicker from '../date-picker/generatePicker';
import { commonProps, datePickerProps, rangePickerProps, } from '../date-picker/generatePicker/props';
import devWarning from '../vc-util/devWarning';
import { useInjectFormItemContext } from '../form/FormItemContext';
import omit from '../_util/omit';
export const timePickerProps = () => ({
    format: String,
    showNow: { type: Boolean, default: undefined },
    showHour: { type: Boolean, default: undefined },
    showMinute: { type: Boolean, default: undefined },
    showSecond: { type: Boolean, default: undefined },
    use12Hours: { type: Boolean, default: undefined },
    hourStep: Number,
    minuteStep: Number,
    secondStep: Number,
    hideDisabledOptions: { type: Boolean, default: undefined },
    popupClassName: String,
});
function createTimePicker(generateConfig) {
    const DatePicker = generatePicker(generateConfig, {
        ...timePickerProps(),
        order: { type: Boolean, default: true },
    });
    const { TimePicker: InternalTimePicker, RangePicker: InternalRangePicker } = DatePicker;
    const TimePicker = defineComponent({
        name: 'ATimePicker',
        inheritAttrs: false,
        props: {
            ...commonProps(),
            ...datePickerProps(),
            ...timePickerProps(),
            addon: { type: Function },
        },
        slot: ['addon', 'renderExtraFooter', 'suffixIcon', 'clearIcon'],
        setup(props, { slots, expose, emit, attrs }) {
            const formItemContext = useInjectFormItemContext();
            if (process.env.NODE_ENV !== 'production')
                devWarning(!(slots.addon || props.addon), 'TimePicker', '`addon` is deprecated. Please use `v-slot:renderExtraFooter` instead.');
            const pickerRef = ref();
            expose({
                focus: () => {
                    pickerRef.value?.focus();
                },
                blur: () => {
                    pickerRef.value?.blur();
                },
            });
            const onChange = (value, dateString) => {
                emit('update:value', value);
                emit('change', value, dateString);
                formItemContext.onFieldChange();
            };
            const onOpenChange = (open) => {
                emit('update:open', open);
                emit('openChange', open);
            };
            const onFocus = (e) => {
                emit('focus', e);
            };
            const onBlur = (e) => {
                emit('blur', e);
                formItemContext.onFieldBlur();
            };
            const onOk = (value) => {
                emit('ok', value);
            };
            return () => {
                const { id = formItemContext.id.value } = props;
                //restProps.addon
                return (<InternalTimePicker {...attrs} {...omit(props, ['onUpdate:value', 'onUpdate:open'])} id={id} dropdownClassName={props.popupClassName} mode={undefined} ref={pickerRef} renderExtraFooter={props.addon || slots.addon || props.renderExtraFooter || slots.renderExtraFooter} onChange={onChange} onOpenChange={onOpenChange} onFocus={onFocus} onBlur={onBlur} onOk={onOk} v-slots={slots}/>);
            };
        },
    });
    const TimeRangePicker = defineComponent({
        name: 'ATimeRangePicker',
        inheritAttrs: false,
        props: {
            ...commonProps(),
            ...rangePickerProps(),
            ...timePickerProps(),
            order: { type: Boolean, default: true },
        },
        slot: ['renderExtraFooter', 'suffixIcon', 'clearIcon'],
        setup(props, { slots, expose, emit, attrs }) {
            const pickerRef = ref();
            const formItemContext = useInjectFormItemContext();
            expose({
                focus: () => {
                    pickerRef.value?.focus();
                },
                blur: () => {
                    pickerRef.value?.blur();
                },
            });
            const onChange = (values, dateStrings) => {
                emit('update:value', values);
                emit('change', values, dateStrings);
                formItemContext.onFieldChange();
            };
            const onOpenChange = (open) => {
                emit('update:open', open);
                emit('openChange', open);
            };
            const onFocus = (e) => {
                emit('focus', e);
            };
            const onBlur = (e) => {
                emit('blur', e);
                formItemContext.onFieldBlur();
            };
            const onPanelChange = (values, modes) => {
                emit('panelChange', values, modes);
            };
            const onOk = (values) => {
                emit('ok', values);
            };
            const onCalendarChange = (values, dateStrings, info) => {
                emit('calendarChange', values, dateStrings, info);
            };
            return () => {
                const { id = formItemContext.id.value } = props;
                return (<InternalRangePicker {...attrs} {...omit(props, ['onUpdate:open', 'onUpdate:value'])} id={id} dropdownClassName={props.popupClassName} picker="time" mode={undefined} ref={pickerRef} onChange={onChange} onOpenChange={onOpenChange} onFocus={onFocus} onBlur={onBlur} onPanelChange={onPanelChange} onOk={onOk} onCalendarChange={onCalendarChange} v-slots={slots}/>);
            };
        },
    });
    return {
        TimePicker,
        TimeRangePicker,
    };
}
export default createTimePicker;
