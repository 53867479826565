import { createVNode, render as vueRender } from 'vue';
import ConfirmDialog from './ConfirmDialog';
import { destroyFns } from './Modal';
import ConfigProvider, { globalConfigForApi } from '../config-provider';
import omit from '../_util/omit';
import InfoCircleOutlined from '@ant-design/icons-vue/InfoCircleOutlined';
import CheckCircleOutlined from '@ant-design/icons-vue/CheckCircleOutlined';
import CloseCircleOutlined from '@ant-design/icons-vue/CloseCircleOutlined';
import ExclamationCircleOutlined from '@ant-design/icons-vue/ExclamationCircleOutlined';
const confirm = (config) => {
    const container = document.createDocumentFragment();
    let currentConfig = {
        ...omit(config, ['parentContext', 'appContext']),
        close,
        visible: true,
    };
    let confirmDialogInstance = null;
    function destroy(...args) {
        if (confirmDialogInstance) {
            // destroy
            vueRender(null, container);
            confirmDialogInstance.component.update();
            confirmDialogInstance = null;
        }
        const triggerCancel = args.some(param => param && param.triggerCancel);
        if (config.onCancel && triggerCancel) {
            config.onCancel(...args);
        }
        for (let i = 0; i < destroyFns.length; i++) {
            const fn = destroyFns[i];
            if (fn === close) {
                destroyFns.splice(i, 1);
                break;
            }
        }
    }
    function close(...args) {
        currentConfig = {
            ...currentConfig,
            visible: false,
            afterClose: () => {
                if (typeof config.afterClose === 'function') {
                    config.afterClose();
                }
                destroy.apply(this, args);
            },
        };
        update(currentConfig);
    }
    function update(configUpdate) {
        if (typeof configUpdate === 'function') {
            currentConfig = configUpdate(currentConfig);
        }
        else {
            currentConfig = {
                ...currentConfig,
                ...configUpdate,
            };
        }
        if (confirmDialogInstance) {
            Object.assign(confirmDialogInstance.component.props, currentConfig);
            confirmDialogInstance.component.update();
        }
    }
    const Wrapper = (p) => {
        const global = globalConfigForApi;
        const rootPrefixCls = global.prefixCls;
        const prefixCls = p.prefixCls || `${rootPrefixCls}-modal`;
        return (<ConfigProvider {...global} notUpdateGlobalConfig={true} prefixCls={rootPrefixCls}>
        <ConfirmDialog {...p} rootPrefixCls={rootPrefixCls} prefixCls={prefixCls}></ConfirmDialog>
      </ConfigProvider>);
    };
    function render(props) {
        const vm = createVNode(Wrapper, { ...props });
        vm.appContext = config.parentContext || config.appContext || vm.appContext;
        vueRender(vm, container);
        return vm;
    }
    confirmDialogInstance = render(currentConfig);
    destroyFns.push(close);
    return {
        destroy: close,
        update,
    };
};
export default confirm;
export function withWarn(props) {
    return {
        icon: () => <ExclamationCircleOutlined />,
        okCancel: false,
        ...props,
        type: 'warning',
    };
}
export function withInfo(props) {
    return {
        icon: () => <InfoCircleOutlined />,
        okCancel: false,
        ...props,
        type: 'info',
    };
}
export function withSuccess(props) {
    return {
        icon: () => <CheckCircleOutlined />,
        okCancel: false,
        ...props,
        type: 'success',
    };
}
export function withError(props) {
    return {
        icon: () => <CloseCircleOutlined />,
        okCancel: false,
        ...props,
        type: 'error',
    };
}
export function withConfirm(props) {
    return {
        icon: () => <ExclamationCircleOutlined />,
        okCancel: true,
        ...props,
        type: 'confirm',
    };
}
