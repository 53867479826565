import { onBeforeMount, ref, defineComponent, onBeforeUnmount, provide, toRef, computed, } from 'vue';
import warning from '../_util/warning';
import ResponsiveObserve, { responsiveArray } from '../_util/responsiveObserve';
import Row from './Row';
import PropTypes from '../_util/vue-types';
import { cloneElement } from '../_util/vnode';
import { flattenChildren } from '../_util/props-util';
import useConfigInject from '../_util/hooks/useConfigInject';
export const DescriptionsItemProps = {
    prefixCls: String,
    label: PropTypes.any,
    span: Number,
};
const descriptionsItemProp = () => ({
    prefixCls: String,
    label: PropTypes.any,
    labelStyle: { type: Object, default: undefined },
    contentStyle: { type: Object, default: undefined },
    span: { type: Number, default: 1 },
});
export const DescriptionsItem = defineComponent({
    compatConfig: { MODE: 3 },
    name: 'ADescriptionsItem',
    props: descriptionsItemProp(),
    slots: ['label'],
    setup(_, { slots }) {
        return () => slots.default?.();
    },
});
const DEFAULT_COLUMN_MAP = {
    xxxl: 3,
    xxl: 3,
    xl: 3,
    lg: 3,
    md: 3,
    sm: 2,
    xs: 1,
};
function getColumn(column, screens) {
    if (typeof column === 'number') {
        return column;
    }
    if (typeof column === 'object') {
        for (let i = 0; i < responsiveArray.length; i++) {
            const breakpoint = responsiveArray[i];
            if (screens[breakpoint] && column[breakpoint] !== undefined) {
                return column[breakpoint] || DEFAULT_COLUMN_MAP[breakpoint];
            }
        }
    }
    return 3;
}
function getFilledItem(node, span, rowRestCol) {
    let clone = node;
    if (span === undefined || span > rowRestCol) {
        clone = cloneElement(node, {
            span: rowRestCol,
        });
        warning(span === undefined, 'Descriptions', 'Sum of column `span` in a line not match `column` of Descriptions.');
    }
    return clone;
}
function getRows(children, column) {
    const childNodes = flattenChildren(children);
    const rows = [];
    let tmpRow = [];
    let rowRestCol = column;
    childNodes.forEach((node, index) => {
        const span = node.props?.span;
        const mergedSpan = span || 1;
        // Additional handle last one
        if (index === childNodes.length - 1) {
            tmpRow.push(getFilledItem(node, span, rowRestCol));
            rows.push(tmpRow);
            return;
        }
        if (mergedSpan < rowRestCol) {
            rowRestCol -= mergedSpan;
            tmpRow.push(node);
        }
        else {
            tmpRow.push(getFilledItem(node, mergedSpan, rowRestCol));
            rows.push(tmpRow);
            rowRestCol = column;
            tmpRow = [];
        }
    });
    return rows;
}
export const descriptionsProps = () => ({
    prefixCls: String,
    bordered: { type: Boolean, default: undefined },
    size: { type: String, default: 'default' },
    title: PropTypes.any,
    extra: PropTypes.any,
    column: {
        type: [Number, Object],
        default: () => DEFAULT_COLUMN_MAP,
    },
    layout: String,
    colon: { type: Boolean, default: undefined },
    labelStyle: { type: Object, default: undefined },
    contentStyle: { type: Object, default: undefined },
});
export const descriptionsContext = Symbol('descriptionsContext');
const Descriptions = defineComponent({
    compatConfig: { MODE: 3 },
    name: 'ADescriptions',
    props: descriptionsProps(),
    slots: ['title', 'extra'],
    Item: DescriptionsItem,
    setup(props, { slots }) {
        const { prefixCls, direction } = useConfigInject('descriptions', props);
        let token;
        const screens = ref({});
        onBeforeMount(() => {
            token = ResponsiveObserve.subscribe(screen => {
                if (typeof props.column !== 'object') {
                    return;
                }
                screens.value = screen;
            });
        });
        onBeforeUnmount(() => {
            ResponsiveObserve.unsubscribe(token);
        });
        provide(descriptionsContext, {
            labelStyle: toRef(props, 'labelStyle'),
            contentStyle: toRef(props, 'contentStyle'),
        });
        const mergeColumn = computed(() => getColumn(props.column, screens.value));
        return () => {
            const { size, bordered = false, layout = 'horizontal', colon = true, title = slots.title?.(), extra = slots.extra?.(), } = props;
            const children = slots.default?.();
            const rows = getRows(children, mergeColumn.value);
            return (<div class={[
                    prefixCls.value,
                    {
                        [`${prefixCls.value}-${size}`]: size !== 'default',
                        [`${prefixCls.value}-bordered`]: !!bordered,
                        [`${prefixCls.value}-rtl`]: direction.value === 'rtl',
                    },
                ]}>
          {(title || extra) && (<div class={`${prefixCls.value}-header`}>
              {title && <div class={`${prefixCls.value}-title`}>{title}</div>}
              {extra && <div class={`${prefixCls.value}-extra`}>{extra}</div>}
            </div>)}
          <div class={`${prefixCls.value}-view`}>
            <table>
              <tbody>
                {rows.map((row, index) => (<Row key={index} index={index} colon={colon} prefixCls={prefixCls.value} vertical={layout === 'vertical'} bordered={bordered} row={row}/>))}
              </tbody>
            </table>
          </div>
        </div>);
        };
    },
});
Descriptions.install = function (app) {
    app.component(Descriptions.name, Descriptions);
    app.component(Descriptions.Item.name, Descriptions.Item);
    return app;
};
export default Descriptions;
