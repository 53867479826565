import { onUnmounted, watch, ref } from 'vue';
import wrapperRaf from '../../_util/raf';
export default function useHeights(mergedData, getKey, onItemAdd, onItemRemove) {
    const instance = new Map();
    const heights = new Map();
    const updatedMark = ref(Symbol('update'));
    watch(mergedData, () => {
        updatedMark.value = Symbol('update');
    });
    let collectRaf = undefined;
    function cancelRaf() {
        wrapperRaf.cancel(collectRaf);
    }
    function collectHeight() {
        cancelRaf();
        collectRaf = wrapperRaf(() => {
            instance.forEach((element, key) => {
                if (element && element.offsetParent) {
                    const { offsetHeight } = element;
                    if (heights.get(key) !== offsetHeight) {
                        //changed = true;
                        updatedMark.value = Symbol('update');
                        heights.set(key, element.offsetHeight);
                    }
                }
            });
        });
    }
    function setInstance(item, ins) {
        const key = getKey(item);
        const origin = instance.get(key);
        if (ins) {
            instance.set(key, ins.$el || ins);
            collectHeight();
        }
        else {
            instance.delete(key);
        }
        // Instance changed
        if (!origin !== !ins) {
            if (ins) {
                onItemAdd?.(item);
            }
            else {
                onItemRemove?.(item);
            }
        }
    }
    onUnmounted(() => {
        cancelRaf();
    });
    return [setInstance, collectHeight, heights, updatedMark];
}
