import { flattenChildren, isValidElement } from '../../_util/props-util';
function convertNodeToOption(node) {
    const { key, children, props: { value, disabled, ...restProps }, } = node;
    const child = children?.default;
    return {
        key,
        value: value !== undefined ? value : key,
        children: child,
        disabled: disabled || disabled === '',
        ...restProps,
    };
}
export function convertChildrenToData(nodes, optionOnly = false) {
    const dd = flattenChildren(nodes)
        .map((node, index) => {
        if (!isValidElement(node) || !node.type) {
            return null;
        }
        const { type: { isSelectOptGroup }, key, children, props, } = node;
        if (optionOnly || !isSelectOptGroup) {
            return convertNodeToOption(node);
        }
        const child = children && children.default ? children.default() : undefined;
        const label = props?.label || children.label?.() || key;
        return {
            key: `__RC_SELECT_GRP__${key === null ? index : String(key)}__`,
            ...props,
            label,
            options: convertChildrenToData(child || []),
        };
    })
        .filter(data => data);
    return dd;
}
