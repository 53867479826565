import get from './get';
function internalSet(entity, paths, value, removeIfUndefined) {
    if (!paths.length) {
        return value;
    }
    const [path, ...restPath] = paths;
    let clone;
    if (!entity && typeof path === 'number') {
        clone = [];
    }
    else if (Array.isArray(entity)) {
        clone = [...entity];
    }
    else {
        clone = { ...entity };
    }
    // Delete prop if `removeIfUndefined` and value is undefined
    if (removeIfUndefined && value === undefined && restPath.length === 1) {
        delete clone[path][restPath[0]];
    }
    else {
        clone[path] = internalSet(clone[path], restPath, value, removeIfUndefined);
    }
    return clone;
}
export default function set(entity, paths, value, removeIfUndefined = false) {
    // Do nothing if `removeIfUndefined` and parent object not exist
    if (paths.length &&
        removeIfUndefined &&
        value === undefined &&
        !get(entity, paths.slice(0, -1))) {
        return entity;
    }
    return internalSet(entity, paths, value, removeIfUndefined);
}
