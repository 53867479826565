import { defineComponent, onBeforeUnmount, ref, watch, watchEffect } from 'vue';
import contains from '../vc-util/Dom/contains';
import classNames from '../_util/classNames';
import KeyCode from '../_util/KeyCode';
import omit from '../_util/omit';
import pickAttrs from '../_util/pickAttrs';
import { initDefaultProps } from '../_util/props-util';
import Content from './Content';
import dialogPropTypes from './IDialogPropTypes';
import Mask from './Mask';
import { getMotionName, getUUID } from './util';
export default defineComponent({
    compatConfig: { MODE: 3 },
    name: 'Dialog',
    inheritAttrs: false,
    props: initDefaultProps({
        ...dialogPropTypes(),
        getOpenCount: Function,
        scrollLocker: Object,
    }, {
        mask: true,
        visible: false,
        keyboard: true,
        closable: true,
        maskClosable: true,
        destroyOnClose: false,
        prefixCls: 'rc-dialog',
        getOpenCount: () => null,
        focusTriggerAfterClose: true,
    }),
    setup(props, { attrs, slots }) {
        const lastOutSideActiveElementRef = ref();
        const wrapperRef = ref();
        const contentRef = ref();
        const animatedVisible = ref(props.visible);
        const ariaIdRef = ref(`vcDialogTitle${getUUID()}`);
        // ========================= Events =========================
        const onDialogVisibleChanged = (newVisible) => {
            if (newVisible) {
                // Try to focus
                if (!contains(wrapperRef.value, document.activeElement)) {
                    lastOutSideActiveElementRef.value = document.activeElement;
                    contentRef.value?.focus();
                }
            }
            else {
                const preAnimatedVisible = animatedVisible.value;
                // Clean up scroll bar & focus back
                animatedVisible.value = false;
                if (props.mask && lastOutSideActiveElementRef.value && props.focusTriggerAfterClose) {
                    try {
                        lastOutSideActiveElementRef.value.focus({ preventScroll: true });
                    }
                    catch (e) {
                        // Do nothing
                    }
                    lastOutSideActiveElementRef.value = null;
                }
                // Trigger afterClose only when change visible from true to false
                if (preAnimatedVisible) {
                    props.afterClose?.();
                }
            }
        };
        const onInternalClose = (e) => {
            props.onClose?.(e);
        };
        // >>> Content
        const contentClickRef = ref(false);
        const contentTimeoutRef = ref();
        // We need record content click incase content popup out of dialog
        const onContentMouseDown = () => {
            clearTimeout(contentTimeoutRef.value);
            contentClickRef.value = true;
        };
        const onContentMouseUp = () => {
            contentTimeoutRef.value = setTimeout(() => {
                contentClickRef.value = false;
            });
        };
        const onWrapperClick = (e) => {
            if (!props.maskClosable)
                return null;
            if (contentClickRef.value) {
                contentClickRef.value = false;
            }
            else if (wrapperRef.value === e.target) {
                onInternalClose(e);
            }
        };
        const onWrapperKeyDown = (e) => {
            if (props.keyboard && e.keyCode === KeyCode.ESC) {
                e.stopPropagation();
                onInternalClose(e);
                return;
            }
            // keep focus inside dialog
            if (props.visible) {
                if (e.keyCode === KeyCode.TAB) {
                    contentRef.value.changeActive(!e.shiftKey);
                }
            }
        };
        watch(() => props.visible, () => {
            if (props.visible) {
                animatedVisible.value = true;
            }
        }, { flush: 'post' });
        onBeforeUnmount(() => {
            clearTimeout(contentTimeoutRef.value);
            props.scrollLocker?.unLock();
        });
        watchEffect(() => {
            props.scrollLocker?.unLock();
            if (animatedVisible.value) {
                props.scrollLocker?.lock();
            }
        });
        return () => {
            const { prefixCls, mask, visible, maskTransitionName, maskAnimation, zIndex, wrapClassName, rootClassName, wrapStyle, closable, maskProps, maskStyle, transitionName, animation, wrapProps, title = slots.title, } = props;
            const { style, class: className } = attrs;
            return (<div class={[`${prefixCls}-root`, rootClassName]} {...pickAttrs(props, { data: true })}>
          <Mask prefixCls={prefixCls} visible={mask && visible} motionName={getMotionName(prefixCls, maskTransitionName, maskAnimation)} style={{
                    zIndex,
                    ...maskStyle,
                }} maskProps={maskProps}/>
          <div tabIndex={-1} onKeydown={onWrapperKeyDown} class={classNames(`${prefixCls}-wrap`, wrapClassName)} ref={wrapperRef} onClick={onWrapperClick} role="dialog" aria-labelledby={title ? ariaIdRef.value : null} style={{ zIndex, ...wrapStyle, display: !animatedVisible.value ? 'none' : null }} {...wrapProps}>
            <Content {...omit(props, ['scrollLocker'])} style={style} class={className} v-slots={slots} onMousedown={onContentMouseDown} onMouseup={onContentMouseUp} ref={contentRef} closable={closable} ariaId={ariaIdRef.value} prefixCls={prefixCls} visible={visible} onClose={onInternalClose} onVisibleChanged={onDialogVisibleChanged} motionName={getMotionName(prefixCls, transitionName, animation)}/>
          </div>
        </div>);
        };
    },
});
