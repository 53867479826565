import { defineComponent } from 'vue';
import PropTypes from '../../_util/vue-types';
const tooltipContentProps = {
    prefixCls: String,
    id: String,
    overlayInnerStyle: PropTypes.any,
};
export default defineComponent({
    compatConfig: { MODE: 3 },
    name: 'Content',
    props: tooltipContentProps,
    slots: ['overlay'],
    setup(props, { slots }) {
        return () => (<div class={`${props.prefixCls}-inner`} id={props.id} role="tooltip" style={props.overlayInnerStyle}>
        {slots.overlay?.()}
      </div>);
    },
});
