import PropTypes from '../_util/vue-types';
import { tuple } from '../_util/type';
export const progressStatuses = tuple('normal', 'exception', 'active', 'success');
const ProgressType = tuple('line', 'circle', 'dashboard');
const ProgressSize = tuple('default', 'small');
export const progressProps = () => ({
    prefixCls: String,
    type: PropTypes.oneOf(ProgressType),
    percent: Number,
    format: { type: Function },
    status: PropTypes.oneOf(progressStatuses),
    showInfo: { type: Boolean, default: undefined },
    strokeWidth: Number,
    strokeLinecap: String,
    strokeColor: {
        type: [String, Object],
        default: undefined,
    },
    trailColor: String,
    width: Number,
    success: {
        type: Object,
        default: () => ({}),
    },
    gapDegree: Number,
    gapPosition: String,
    size: PropTypes.oneOf(ProgressSize),
    steps: Number,
    /** @deprecated Use `success` instead */
    successPercent: Number,
    title: String,
});
