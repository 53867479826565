import classNames from '../_util/classNames';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import DefaultEmptyImg from './empty';
import SimpleEmptyImg from './simple';
import { filterEmpty } from '../_util/props-util';
import PropTypes from '../_util/vue-types';
import { withInstall } from '../_util/type';
import useConfigInject from '../_util/hooks/useConfigInject';
const defaultEmptyImg = <DefaultEmptyImg />;
const simpleEmptyImg = <SimpleEmptyImg />;
const Empty = (props, { slots = {}, attrs }) => {
    const { direction, prefixCls: prefixClsRef } = useConfigInject('empty', props);
    const prefixCls = prefixClsRef.value;
    const { image = defaultEmptyImg, description = slots.description?.() || undefined, imageStyle, class: className = '', ...restProps } = { ...props, ...attrs };
    return (<LocaleReceiver componentName="Empty" children={(locale) => {
            const des = typeof description !== 'undefined' ? description : locale.description;
            const alt = typeof des === 'string' ? des : 'empty';
            let imageNode = null;
            if (typeof image === 'string') {
                imageNode = <img alt={alt} src={image}/>;
            }
            else {
                imageNode = image;
            }
            return (<div class={classNames(prefixCls, className, {
                    [`${prefixCls}-normal`]: image === simpleEmptyImg,
                    [`${prefixCls}-rtl`]: direction.value === 'rtl',
                })} {...restProps}>
            <div class={`${prefixCls}-image`} style={imageStyle}>
              {imageNode}
            </div>
            {des && <p class={`${prefixCls}-description`}>{des}</p>}
            {slots.default && (<div class={`${prefixCls}-footer`}>{filterEmpty(slots.default())}</div>)}
          </div>);
        }}/>);
};
Empty.displayName = 'AEmpty';
Empty.PRESENTED_IMAGE_DEFAULT = defaultEmptyImg;
Empty.PRESENTED_IMAGE_SIMPLE = simpleEmptyImg;
Empty.inheritAttrs = false;
Empty.props = {
    prefixCls: String,
    image: PropTypes.any,
    description: PropTypes.any,
    imageStyle: { type: Object, default: undefined },
};
export default withInstall(Empty);
