import { computed, defineComponent } from 'vue';
import CloseOutlined from '@ant-design/icons-vue/CloseOutlined';
import CheckOutlined from '@ant-design/icons-vue/CheckOutlined';
import PropTypes from '../_util/vue-types';
import initDefaultProps from '../_util/props-util/initDefaultProps';
import VcSteps, { Step as VcStep } from '../vc-steps';
import useConfigInject from '../_util/hooks/useConfigInject';
import useBreakpoint from '../_util/hooks/useBreakpoint';
import classNames from '../_util/classNames';
import Progress from '../progress';
import omit from '../_util/omit';
import { VcStepProps } from '../vc-steps/Step';
export const stepsProps = () => ({
    prefixCls: String,
    iconPrefix: String,
    current: Number,
    initial: Number,
    percent: Number,
    responsive: { type: Boolean, default: undefined },
    labelPlacement: String,
    status: String,
    size: String,
    direction: String,
    progressDot: {
        type: [Boolean, Function],
        default: undefined,
    },
    type: String,
    onChange: Function,
    'onUpdate:current': Function,
});
export const stepProps = () => ({
    description: PropTypes.any,
    icon: PropTypes.any,
    status: String,
    disabled: { type: Boolean, default: undefined },
    title: PropTypes.any,
    subTitle: PropTypes.any,
    onClick: Function,
});
const Steps = defineComponent({
    compatConfig: { MODE: 3 },
    name: 'ASteps',
    inheritAttrs: false,
    props: initDefaultProps(stepsProps(), {
        current: 0,
        responsive: true,
        labelPlacement: 'horizontal',
    }),
    slots: ['progressDot'],
    // emits: ['update:current', 'change'],
    setup(props, { attrs, slots, emit }) {
        const { prefixCls, direction: rtlDirection, configProvider } = useConfigInject('steps', props);
        const screens = useBreakpoint();
        const direction = computed(() => props.responsive && screens.value.xs ? 'vertical' : props.direction);
        const iconPrefix = computed(() => configProvider.getPrefixCls('', props.iconPrefix));
        const handleChange = (current) => {
            emit('update:current', current);
            emit('change', current);
        };
        const stepIconRender = ({ node, status, }) => {
            if (status === 'process' && props.percent !== undefined) {
                // currently it's hard-coded, since we can't easily read the actually width of icon
                const progressWidth = props.size === 'small' ? 32 : 40;
                const iconWithProgress = (<div class={`${prefixCls}-progress-icon`}>
            <Progress type="circle" percent={props.percent} width={progressWidth} strokeWidth={4} format={() => null}/>
            {node}
          </div>);
                return iconWithProgress;
            }
            return node;
        };
        return () => {
            const stepsClassName = classNames({
                [`${prefixCls.value}-rtl`]: rtlDirection.value === 'rtl',
                [`${prefixCls.value}-with-progress`]: props.percent !== undefined,
            }, attrs.class);
            const icons = {
                finish: <CheckOutlined class={`${prefixCls}-finish-icon`}/>,
                error: <CloseOutlined class={`${prefixCls}-error-icon`}/>,
            };
            return (<VcSteps icons={icons} {...omit(props, ['percent', 'responsive'])} direction={direction.value} prefixCls={prefixCls.value} iconPrefix={iconPrefix.value} class={stepsClassName} onChange={handleChange} v-slots={{ ...slots, stepIcon: stepIconRender }}/>);
        };
    },
});
/* istanbul ignore next */
export const Step = defineComponent({
    compatConfig: { MODE: 3 },
    ...VcStep,
    name: 'AStep',
    props: VcStepProps(),
});
export default Object.assign(Steps, {
    Step,
    install: (app) => {
        app.component(Steps.name, Steps);
        app.component(Step.name, Step);
        return app;
    },
});
