import { computed, defineComponent, onBeforeUnmount, watchEffect } from 'vue';
import { useInjectTable } from '../context/TableContext';
let indexGuid = 0;
const Summary = defineComponent({
    name: 'Summary',
    props: ['fixed'],
    setup(props, { slots }) {
        const tableContext = useInjectTable();
        const uniKey = `table-summary-uni-key-${++indexGuid}`;
        const fixed = computed(() => props.fixed === '' || props.fixed);
        watchEffect(() => {
            tableContext.summaryCollect(uniKey, fixed.value);
        });
        onBeforeUnmount(() => {
            tableContext.summaryCollect(uniKey, false);
        });
        return () => slots.default?.();
    },
});
export default Summary;
