import Empty from '../empty';
import useConfigInject from '../_util/hooks/useConfigInject';
const RenderEmpty = (props) => {
    const { prefixCls } = useConfigInject('empty', props);
    const renderHtml = (componentName) => {
        switch (componentName) {
            case 'Table':
            case 'List':
                return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>;
            case 'Select':
            case 'TreeSelect':
            case 'Cascader':
            case 'Transfer':
            case 'Mentions':
                return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} class={`${prefixCls.value}-small`}/>;
            default:
                return <Empty />;
        }
    };
    return renderHtml(props.componentName);
};
function renderEmpty(componentName) {
    return <RenderEmpty componentName={componentName}/>;
}
export default renderEmpty;
