import useMergeProps from '../hooks/useMergeProps';
import { useInjectPanel } from '../PanelContext';
const HIDDEN_STYLE = {
    visibility: 'hidden',
};
function Header(_props, { slots }) {
    const props = useMergeProps(_props);
    const { prefixCls, prevIcon = '\u2039', nextIcon = '\u203A', superPrevIcon = '\u00AB', superNextIcon = '\u00BB', onSuperPrev, onSuperNext, onPrev, onNext, } = props;
    const { hideNextBtn, hidePrevBtn } = useInjectPanel();
    return (<div class={prefixCls}>
      {onSuperPrev && (<button type="button" onClick={onSuperPrev} tabindex={-1} class={`${prefixCls}-super-prev-btn`} style={hidePrevBtn.value ? HIDDEN_STYLE : {}}>
          {superPrevIcon}
        </button>)}
      {onPrev && (<button type="button" onClick={onPrev} tabindex={-1} class={`${prefixCls}-prev-btn`} style={hidePrevBtn.value ? HIDDEN_STYLE : {}}>
          {prevIcon}
        </button>)}
      <div class={`${prefixCls}-view`}>{slots.default?.()}</div>
      {onNext && (<button type="button" onClick={onNext} tabindex={-1} class={`${prefixCls}-next-btn`} style={hideNextBtn.value ? HIDDEN_STYLE : {}}>
          {nextIcon}
        </button>)}
      {onSuperNext && (<button type="button" onClick={onSuperNext} tabindex={-1} class={`${prefixCls}-super-next-btn`} style={hideNextBtn.value ? HIDDEN_STYLE : {}}>
          {superNextIcon}
        </button>)}
    </div>);
}
Header.displayName = 'Header';
Header.inheritAttrs = false;
export default Header;
