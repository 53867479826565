import { cloneElement } from '../../_util/vnode';
import { defineComponent, inject, withDirectives } from 'vue';
import PropTypes from '../../_util/vue-types';
import antInput from '../../_util/antInputDirective';
import classNames from '../../_util/classNames';
export const inputProps = {
    inputRef: PropTypes.any,
    prefixCls: String,
    id: String,
    inputElement: PropTypes.VueNode,
    disabled: { type: Boolean, default: undefined },
    autofocus: { type: Boolean, default: undefined },
    autocomplete: String,
    editable: { type: Boolean, default: undefined },
    activeDescendantId: String,
    value: String,
    open: { type: Boolean, default: undefined },
    tabindex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /** Pass accessibility props to input */
    attrs: PropTypes.object,
    onKeydown: { type: Function },
    onMousedown: { type: Function },
    onChange: { type: Function },
    onPaste: { type: Function },
    onCompositionstart: { type: Function },
    onCompositionend: { type: Function },
    onFocus: { type: Function },
    onBlur: { type: Function },
};
const Input = defineComponent({
    compatConfig: { MODE: 3 },
    name: 'Input',
    inheritAttrs: false,
    props: inputProps,
    setup(props) {
        let blurTimeout = null;
        const VCSelectContainerEvent = inject('VCSelectContainerEvent');
        return () => {
            const { prefixCls, id, inputElement, disabled, tabindex, autofocus, autocomplete, editable, activeDescendantId, value, onKeydown, onMousedown, onChange, onPaste, onCompositionstart, onCompositionend, onFocus, onBlur, open, inputRef, attrs, } = props;
            let inputNode = inputElement || withDirectives((<input />), [[antInput]]);
            const inputProps = inputNode.props || {};
            const { onKeydown: onOriginKeyDown, onInput: onOriginInput, onFocus: onOriginFocus, onBlur: onOriginBlur, onMousedown: onOriginMouseDown, onCompositionstart: onOriginCompositionStart, onCompositionend: onOriginCompositionEnd, style, } = inputProps;
            inputNode = cloneElement(inputNode, Object.assign({
                type: 'search',
                ...inputProps,
                id,
                ref: inputRef,
                disabled,
                tabindex,
                autocomplete: autocomplete || 'off',
                autofocus,
                class: classNames(`${prefixCls}-selection-search-input`, inputNode?.props?.class),
                role: 'combobox',
                'aria-expanded': open,
                'aria-haspopup': 'listbox',
                'aria-owns': `${id}_list`,
                'aria-autocomplete': 'list',
                'aria-controls': `${id}_list`,
                'aria-activedescendant': activeDescendantId,
                ...attrs,
                value: editable ? value : '',
                readonly: !editable,
                unselectable: !editable ? 'on' : null,
                style: { ...style, opacity: editable ? null : 0 },
                onKeydown: (event) => {
                    onKeydown(event);
                    if (onOriginKeyDown) {
                        onOriginKeyDown(event);
                    }
                },
                onMousedown: (event) => {
                    onMousedown(event);
                    if (onOriginMouseDown) {
                        onOriginMouseDown(event);
                    }
                },
                onInput: (event) => {
                    onChange(event);
                    if (onOriginInput) {
                        onOriginInput(event);
                    }
                },
                onCompositionstart(event) {
                    onCompositionstart(event);
                    if (onOriginCompositionStart) {
                        onOriginCompositionStart(event);
                    }
                },
                onCompositionend(event) {
                    onCompositionend(event);
                    if (onOriginCompositionEnd) {
                        onOriginCompositionEnd(event);
                    }
                },
                onPaste,
                onFocus: (...args) => {
                    clearTimeout(blurTimeout);
                    onOriginFocus && onOriginFocus(args[0]);
                    onFocus && onFocus(args[0]);
                    VCSelectContainerEvent?.focus(args[0]);
                },
                onBlur: (...args) => {
                    blurTimeout = setTimeout(() => {
                        onOriginBlur && onOriginBlur(args[0]);
                        onBlur && onBlur(args[0]);
                        VCSelectContainerEvent?.blur(args[0]);
                    }, 100);
                },
            }, inputNode.type === 'textarea' ? {} : { type: 'search' }), true, true);
            return inputNode;
        };
    },
});
export default Input;
