import { camelize } from 'vue';
import { flattenChildren } from '../_util/props-util';
export function getColumnKey(column, defaultKey) {
    if ('key' in column && column.key !== undefined && column.key !== null) {
        return column.key;
    }
    if (column.dataIndex) {
        return (Array.isArray(column.dataIndex) ? column.dataIndex.join('.') : column.dataIndex);
    }
    return defaultKey;
}
export function getColumnPos(index, pos) {
    return pos ? `${pos}-${index}` : `${index}`;
}
export function renderColumnTitle(title, props) {
    if (typeof title === 'function') {
        return title(props);
    }
    return title;
}
export function convertChildrenToColumns(elements = []) {
    const flattenElements = flattenChildren(elements);
    const columns = [];
    flattenElements.forEach(element => {
        if (!element) {
            return;
        }
        const key = element.key;
        const style = element.props?.style || {};
        const cls = element.props?.class || '';
        const props = element.props || {};
        for (const [k, v] of Object.entries(props)) {
            props[camelize(k)] = v;
        }
        const { default: children, ...restSlots } = element.children || {};
        const column = { ...restSlots, ...props, style, class: cls };
        if (key) {
            column.key = key;
        }
        if (element.type?.__ANT_TABLE_COLUMN_GROUP) {
            column.children = convertChildrenToColumns(typeof children === 'function' ? children() : children);
        }
        else {
            const customRender = element.children?.default;
            column.customRender = column.customRender || customRender;
        }
        columns.push(column);
    });
    return columns;
}
