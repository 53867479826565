import { computed, inject } from 'vue';
import { defaultConfigProvider } from '../../config-provider';
export default (name, props) => {
    const configProvider = inject('configProvider', defaultConfigProvider);
    const prefixCls = computed(() => configProvider.getPrefixCls(name, props.prefixCls));
    const direction = computed(() => props.direction ?? configProvider.direction);
    const rootPrefixCls = computed(() => configProvider.getPrefixCls());
    const autoInsertSpaceInButton = computed(() => configProvider.autoInsertSpaceInButton);
    const renderEmpty = computed(() => configProvider.renderEmpty);
    const space = computed(() => configProvider.space);
    const pageHeader = computed(() => configProvider.pageHeader);
    const form = computed(() => configProvider.form);
    const getTargetContainer = computed(() => props.getTargetContainer || configProvider.getTargetContainer);
    const getPopupContainer = computed(() => props.getPopupContainer || configProvider.getPopupContainer);
    const dropdownMatchSelectWidth = computed(() => props.dropdownMatchSelectWidth ?? configProvider.dropdownMatchSelectWidth);
    const virtual = computed(() => (props.virtual === undefined ? configProvider.virtual !== false : props.virtual !== false) &&
        dropdownMatchSelectWidth.value !== false);
    const size = computed(() => props.size || configProvider.componentSize);
    const autocomplete = computed(() => props.autocomplete || configProvider.input?.autocomplete);
    const csp = computed(() => configProvider.csp);
    return {
        configProvider,
        prefixCls,
        direction,
        size,
        getTargetContainer,
        getPopupContainer,
        space,
        pageHeader,
        form,
        autoInsertSpaceInButton,
        renderEmpty,
        virtual,
        dropdownMatchSelectWidth,
        rootPrefixCls,
        getPrefixCls: configProvider.getPrefixCls,
        autocomplete,
        csp,
    };
};
