import { defineComponent } from 'vue';
export const optionProps = {
    value: String,
    disabled: Boolean,
    label: [String, Number, Function],
};
export const optionOptions = {
    name: 'Option',
    props: optionProps,
    render(_props, { slots }) {
        return slots.default?.();
    },
};
export default defineComponent({
    compatConfig: { MODE: 3 },
    ...optionOptions,
});
