export const innerProps = {
    visible: Boolean,
    prefixCls: String,
    zIndex: Number,
    destroyPopupOnHide: Boolean,
    forceRender: Boolean,
    // Legacy Motion
    animation: [String, Object],
    transitionName: String,
    // Measure
    stretch: { type: String },
    // Align
    align: { type: Object },
    point: { type: Object },
    getRootDomNode: { type: Function },
    getClassNameFromAlign: { type: Function },
    onMouseenter: { type: Function },
    onMouseleave: { type: Function },
    onMousedown: { type: Function },
    onTouchstart: { type: Function },
};
export const mobileProps = {
    ...innerProps,
    mobile: { type: Object },
};
export const popupProps = {
    ...innerProps,
    mask: Boolean,
    mobile: { type: Object },
    maskAnimation: String,
    maskTransitionName: String,
};
