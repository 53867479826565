import { computed, inject, provide } from 'vue';
const TriggerContextKey = Symbol('TriggerContextKey');
export const useProviderTrigger = () => {
    let portal = null;
    provide(TriggerContextKey, {
        setPortal(val) {
            portal = val;
        },
        popPortal: true,
    });
    return () => {
        return portal;
    };
};
export const useInjectTrigger = (tryPopPortal) => {
    return tryPopPortal
        ? inject(TriggerContextKey, { setPortal: () => { }, popPortal: false })
        : { setPortal: () => { }, popPortal: false };
};
const PortalContextKey = Symbol('PortalContextKey');
export const useProvidePortal = (instance, config = { inTriggerContext: true }) => {
    provide(PortalContextKey, {
        inTriggerContext: config.inTriggerContext,
        shouldRender: computed(() => {
            const { sPopupVisible, popupRef, forceRender, autoDestroy } = instance || {};
            // if (popPortal) return true;
            let shouldRender = false;
            if (sPopupVisible || popupRef || forceRender) {
                shouldRender = true;
            }
            if (!sPopupVisible && autoDestroy) {
                shouldRender = false;
            }
            return shouldRender;
        }),
    });
};
export const useInjectPortal = () => {
    useProvidePortal({}, { inTriggerContext: false });
    const portalContext = inject(PortalContextKey, {
        shouldRender: computed(() => false),
        inTriggerContext: false,
    });
    return {
        shouldRender: computed(() => portalContext.shouldRender.value || portalContext.inTriggerContext === false),
    };
};
