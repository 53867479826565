import { tryOnScopeDispose } from './tryOnScopeDispose';
import { watch } from 'vue';
import { unrefElement } from './unrefElement';
import { useSupported } from './useSupported';
import { defaultWindow } from './_configurable';
/**
 * Reports changes to the dimensions of an Element's content or the border-box
 *
 * @see https://vueuse.org/useResizeObserver
 * @param target
 * @param callback
 * @param options
 */
export function useResizeObserver(target, callback, options = {}) {
    const { window = defaultWindow, ...observerOptions } = options;
    let observer;
    const isSupported = useSupported(() => window && 'ResizeObserver' in window);
    const cleanup = () => {
        if (observer) {
            observer.disconnect();
            observer = undefined;
        }
    };
    const stopWatch = watch(() => unrefElement(target), el => {
        cleanup();
        if (isSupported.value && window && el) {
            observer = new ResizeObserver(callback);
            observer.observe(el, observerOptions);
        }
    }, { immediate: true, flush: 'post' });
    const stop = () => {
        cleanup();
        stopWatch();
    };
    tryOnScopeDispose(stop);
    return {
        isSupported,
        stop,
    };
}
