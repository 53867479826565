import classNames from '../_util/classNames';
import { filterEmpty } from '../_util/props-util';
export function getInputClassName(prefixCls, bordered, size, disabled, direction) {
    return classNames(prefixCls, {
        [`${prefixCls}-sm`]: size === 'small',
        [`${prefixCls}-lg`]: size === 'large',
        [`${prefixCls}-disabled`]: disabled,
        [`${prefixCls}-rtl`]: direction === 'rtl',
        [`${prefixCls}-borderless`]: !bordered,
    });
}
const isValid = (value) => {
    return (value !== undefined &&
        value !== null &&
        (Array.isArray(value) ? filterEmpty(value).length : true));
};
export function hasPrefixSuffix(propsAndSlots) {
    return (isValid(propsAndSlots.prefix) ||
        isValid(propsAndSlots.suffix) ||
        isValid(propsAndSlots.allowClear));
}
export function hasAddon(propsAndSlots) {
    return isValid(propsAndSlots.addonBefore) || isValid(propsAndSlots.addonAfter);
}
