import Summary from './Summary';
import SummaryRow from './Row';
import SummaryCell from './Cell';
import { computed, defineComponent, reactive, toRef } from 'vue';
import { useProvideSummary } from '../context/SummaryContext';
import { useInjectTable } from '../context/TableContext';
export default defineComponent({
    name: 'Footer',
    inheritAttrs: false,
    props: ['stickyOffsets', 'flattenColumns'],
    setup(props, { slots }) {
        const tableContext = useInjectTable();
        useProvideSummary(reactive({
            stickyOffsets: toRef(props, 'stickyOffsets'),
            flattenColumns: toRef(props, 'flattenColumns'),
            scrollColumnIndex: computed(() => {
                const lastColumnIndex = props.flattenColumns.length - 1;
                const scrollColumn = props.flattenColumns[lastColumnIndex];
                return scrollColumn?.scrollbar ? lastColumnIndex : null;
            }),
        }));
        return () => {
            const { prefixCls } = tableContext;
            return <tfoot class={`${prefixCls}-summary`}>{slots.default?.()}</tfoot>;
        };
    },
});
export { SummaryRow, SummaryCell };
export const FooterComponents = Summary;
