import { defineComponent, inject } from 'vue';
import Radio, { radioProps } from './Radio';
import useConfigInject from '../_util/hooks/useConfigInject';
export default defineComponent({
    compatConfig: { MODE: 3 },
    name: 'ARadioButton',
    props: radioProps(),
    setup(props, { slots }) {
        const { prefixCls } = useConfigInject('radio-button', props);
        const radioGroupContext = inject('radioGroupContext', undefined);
        return () => {
            const rProps = {
                ...props,
                prefixCls: prefixCls.value,
            };
            if (radioGroupContext) {
                rProps.onChange = radioGroupContext.onRadioChange;
                rProps.checked = rProps.value === radioGroupContext.stateValue.value;
                rProps.disabled = rProps.disabled || radioGroupContext.props.disabled;
            }
            return <Radio {...rProps}>{slots.default?.()}</Radio>;
        };
    },
});
