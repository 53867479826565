import { onBeforeUnmount, onMounted } from 'vue';
export default function useSelectTriggerControl(refs, open, triggerOpen) {
    function onGlobalMouseDown(event) {
        let target = event.target;
        if (target.shadowRoot && event.composed) {
            target = (event.composedPath()[0] || target);
        }
        const elements = [refs[0]?.value, refs[1]?.value?.getPopupElement()];
        if (open.value &&
            elements.every(element => element && !element.contains(target) && element !== target)) {
            // Should trigger close
            triggerOpen(false);
        }
    }
    onMounted(() => {
        window.addEventListener('mousedown', onGlobalMouseDown);
    });
    onBeforeUnmount(() => {
        window.removeEventListener('mousedown', onGlobalMouseDown);
    });
}
