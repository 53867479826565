import PropTypes from '../_util/vue-types';
export const abstractCheckboxGroupProps = () => {
    return {
        name: String,
        prefixCls: String,
        options: {
            type: Array,
            default: () => [],
        },
        disabled: Boolean,
        id: String,
    };
};
export const checkboxGroupProps = () => {
    return {
        ...abstractCheckboxGroupProps(),
        defaultValue: { type: Array },
        value: { type: Array },
        onChange: { type: Function },
        'onUpdate:value': {
            type: Function,
        },
    };
};
export const abstractCheckboxProps = () => {
    return {
        prefixCls: String,
        defaultChecked: { type: Boolean, default: undefined },
        checked: { type: Boolean, default: undefined },
        disabled: { type: Boolean, default: undefined },
        isGroup: { type: Boolean, default: undefined },
        value: PropTypes.any,
        name: String,
        id: String,
        indeterminate: { type: Boolean, default: undefined },
        type: { type: String, default: 'checkbox' },
        autofocus: { type: Boolean, default: undefined },
        onChange: Function,
        'onUpdate:checked': Function,
        onClick: Function,
        skipGroup: { type: Boolean, default: false },
    };
};
export const checkboxProps = () => {
    return {
        ...abstractCheckboxProps(),
        indeterminate: { type: Boolean, default: false },
    };
};
export const CheckboxGroupContextKey = Symbol('CheckboxGroupContext');
