import { computed, defineComponent, inject, provide } from 'vue';
const OverflowContextProviderKey = Symbol('OverflowContextProviderKey');
export const OverflowContextProvider = defineComponent({
    compatConfig: { MODE: 3 },
    name: 'OverflowContextProvider',
    inheritAttrs: false,
    props: {
        value: { type: Object },
    },
    setup(props, { slots }) {
        provide(OverflowContextProviderKey, computed(() => props.value));
        return () => slots.default?.();
    },
});
export const useInjectOverflowContext = () => {
    return inject(OverflowContextProviderKey, computed(() => null));
};
