import { defineComponent } from 'vue';
import useConfigInject from '../_util/hooks/useConfigInject';
import classNames from '../_util/classNames';
export const typographyProps = () => ({
    prefixCls: String,
    direction: String,
    // Form Internal use
    component: String,
});
const Typography = defineComponent({
    name: 'ATypography',
    inheritAttrs: false,
    props: typographyProps(),
    setup(props, { slots, attrs }) {
        const { prefixCls, direction } = useConfigInject('typography', props);
        return () => {
            const { prefixCls: _prefixCls, class: _className, direction: _direction, component: Component = 'article', ...restProps } = { ...props, ...attrs };
            return (<Component class={classNames(prefixCls.value, { [`${prefixCls.value}-rtl`]: direction.value === 'rtl' }, attrs.class)} {...restProps}>
          {slots.default?.()}
        </Component>);
        };
    },
});
export default Typography;
