import { watch, ref, defineComponent } from 'vue';
import classNames from '../_util/classNames';
import PropTypes from '../_util/vue-types';
import VcMentions from '../vc-mentions';
import { mentionsProps as baseMentionsProps } from '../vc-mentions/src/mentionsProps';
import useConfigInject from '../_util/hooks/useConfigInject';
import { flattenChildren, getOptionProps } from '../_util/props-util';
import { useInjectFormItemContext } from '../form/FormItemContext';
import omit from '../_util/omit';
import { optionProps, optionOptions } from '../vc-mentions/src/Option';
const getMentions = (value = '', config = {}) => {
    const { prefix = '@', split = ' ' } = config;
    const prefixList = Array.isArray(prefix) ? prefix : [prefix];
    return value
        .split(split)
        .map((str = '') => {
        let hitPrefix = null;
        prefixList.some(prefixStr => {
            const startStr = str.slice(0, prefixStr.length);
            if (startStr === prefixStr) {
                hitPrefix = prefixStr;
                return true;
            }
            return false;
        });
        if (hitPrefix !== null) {
            return {
                prefix: hitPrefix,
                value: str.slice(hitPrefix.length),
            };
        }
        return null;
    })
        .filter((entity) => !!entity && !!entity.value);
};
export const mentionsProps = () => ({
    ...baseMentionsProps,
    loading: { type: Boolean, default: undefined },
    onFocus: {
        type: Function,
    },
    onBlur: {
        type: Function,
    },
    onSelect: {
        type: Function,
    },
    onChange: {
        type: Function,
    },
    onPressenter: {
        type: Function,
    },
    'onUpdate:value': {
        type: Function,
    },
    notFoundContent: PropTypes.any,
    defaultValue: String,
    id: String,
});
const Mentions = defineComponent({
    compatConfig: { MODE: 3 },
    name: 'AMentions',
    inheritAttrs: false,
    props: mentionsProps(),
    slots: ['notFoundContent', 'option'],
    setup(props, { slots, emit, attrs, expose }) {
        const { prefixCls, renderEmpty, direction } = useConfigInject('mentions', props);
        const focused = ref(false);
        const vcMentions = ref(null);
        const value = ref(props.value ?? props.defaultValue ?? '');
        const formItemContext = useInjectFormItemContext();
        watch(() => props.value, val => {
            value.value = val;
        });
        const handleFocus = (e) => {
            focused.value = true;
            emit('focus', e);
        };
        const handleBlur = (e) => {
            focused.value = false;
            emit('blur', e);
            formItemContext.onFieldBlur();
        };
        const handleSelect = (...args) => {
            emit('select', ...args);
            focused.value = true;
        };
        const handleChange = (val) => {
            if (props.value === undefined) {
                value.value = val;
            }
            emit('update:value', val);
            emit('change', val);
            formItemContext.onFieldChange();
        };
        const getNotFoundContent = () => {
            const notFoundContent = props.notFoundContent;
            if (notFoundContent !== undefined) {
                return notFoundContent;
            }
            if (slots.notFoundContent) {
                return slots.notFoundContent();
            }
            return renderEmpty.value('Select');
        };
        const getOptions = () => {
            return flattenChildren(slots.default?.() || []).map(item => {
                return { ...getOptionProps(item), label: item.children?.default?.() };
            });
        };
        const focus = () => {
            vcMentions.value.focus();
        };
        const blur = () => {
            vcMentions.value.blur();
        };
        expose({ focus, blur });
        return () => {
            const { disabled, getPopupContainer, rows = 1, id = formItemContext.id.value, ...restProps } = props;
            const { class: className, ...otherAttrs } = attrs;
            const otherProps = omit(restProps, ['defaultValue', 'onUpdate:value', 'prefixCls']);
            const mergedClassName = classNames(className, {
                [`${prefixCls.value}-disabled`]: disabled,
                [`${prefixCls.value}-focused`]: focused.value,
                [`${prefixCls.value}-rtl`]: direction.value === 'rtl',
            });
            const mentionsProps = {
                prefixCls: prefixCls.value,
                ...otherProps,
                disabled,
                direction: direction.value,
                filterOption: props.filterOption,
                getPopupContainer,
                options: props.options || getOptions(),
                class: mergedClassName,
                ...otherAttrs,
                rows,
                onChange: handleChange,
                onSelect: handleSelect,
                onFocus: handleFocus,
                onBlur: handleBlur,
                ref: vcMentions,
                value: value.value,
                id,
            };
            return (<VcMentions {...mentionsProps} v-slots={{ notFoundContent: getNotFoundContent, option: slots.option }}></VcMentions>);
        };
    },
});
/* istanbul ignore next */
export const MentionsOption = defineComponent({
    compatConfig: { MODE: 3 },
    ...optionOptions,
    name: 'AMentionsOption',
    props: optionProps,
});
export default Object.assign(Mentions, {
    Option: MentionsOption,
    getMentions,
    install: (app) => {
        app.component(Mentions.name, Mentions);
        app.component(MentionsOption.name, MentionsOption);
        return app;
    },
});
