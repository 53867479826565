import { isPresetColor } from './utils';
import { defineComponent, computed } from 'vue';
import PropTypes from '../_util/vue-types';
import useConfigInject from '../_util/hooks/useConfigInject';
export const ribbonProps = () => ({
    prefix: String,
    color: { type: String },
    text: PropTypes.any,
    placement: { type: String, default: 'end' },
});
export default defineComponent({
    compatConfig: { MODE: 3 },
    name: 'ABadgeRibbon',
    inheritAttrs: false,
    props: ribbonProps(),
    slots: ['text'],
    setup(props, { attrs, slots }) {
        const { prefixCls, direction } = useConfigInject('ribbon', props);
        const colorInPreset = computed(() => isPresetColor(props.color));
        const ribbonCls = computed(() => [
            prefixCls.value,
            `${prefixCls.value}-placement-${props.placement}`,
            {
                [`${prefixCls.value}-rtl`]: direction.value === 'rtl',
                [`${prefixCls.value}-color-${props.color}`]: colorInPreset.value,
            },
        ]);
        return () => {
            const { class: className, style, ...restAttrs } = attrs;
            const colorStyle = {};
            const cornerColorStyle = {};
            if (props.color && !colorInPreset.value) {
                colorStyle.background = props.color;
                cornerColorStyle.color = props.color;
            }
            return (<div class={`${prefixCls.value}-wrapper`} {...restAttrs}>
          {slots.default?.()}
          <div class={[ribbonCls.value, className]} style={{ ...colorStyle, ...style }}>
            <span class={`${prefixCls.value}-text`}>{props.text || slots.text?.()}</span>
            <div class={`${prefixCls.value}-corner`} style={cornerColorStyle}/>
          </div>
        </div>);
        };
    },
});
