import { cloneVNode } from 'vue';
import { flattenChildren } from '../_util/props-util';
const Item = ({ setRef }, { slots }) => {
    const children = flattenChildren(slots.default?.());
    return children && children.length
        ? cloneVNode(children[0], {
            ref: setRef,
        })
        : children;
};
Item.props = {
    setRef: {
        type: Function,
        default: () => { },
    },
};
export default Item;
