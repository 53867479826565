import CalendarOutlined from '@ant-design/icons-vue/CalendarOutlined';
import ClockCircleOutlined from '@ant-design/icons-vue/ClockCircleOutlined';
import CloseCircleFilled from '@ant-design/icons-vue/CloseCircleFilled';
import RCPicker from '../../vc-picker';
import enUS from '../locale/en_US';
import { getPlaceholder } from '../util';
import { useLocaleReceiver } from '../../locale-provider/LocaleReceiver';
import { getTimeProps, Components } from '.';
import { computed, defineComponent, ref } from 'vue';
import useConfigInject from '../../_util/hooks/useConfigInject';
import classNames from '../../_util/classNames';
import { commonProps, datePickerProps } from './props';
import devWarning from '../../vc-util/devWarning';
import { useInjectFormItemContext } from '../../form/FormItemContext';
export default function generateSinglePicker(generateConfig, extraProps) {
    function getPicker(picker, displayName) {
        const comProps = {
            ...commonProps(),
            ...datePickerProps(),
            ...extraProps,
        };
        return defineComponent({
            compatConfig: { MODE: 3 },
            name: displayName,
            inheritAttrs: false,
            props: comProps,
            slots: [
                'suffixIcon',
                // 'clearIcon',
                'prevIcon',
                'nextIcon',
                'superPrevIcon',
                'superNextIcon',
                // 'panelRender',
                'dateRender',
                'renderExtraFooter',
                'monthCellRender',
            ],
            setup(_props, { slots, expose, attrs, emit }) {
                // 兼容 vue 3.2.7
                const props = _props;
                const formItemContext = useInjectFormItemContext();
                if (process.env.NODE_ENV !== 'production')
                    devWarning(!(props.monthCellContentRender || slots.monthCellContentRender), 'DatePicker', '`monthCellContentRender` is deprecated. Please use `monthCellRender"` instead.');
                devWarning(!attrs.getCalendarContainer, 'DatePicker', '`getCalendarContainer` is deprecated. Please use `getPopupContainer"` instead.');
                const { prefixCls, direction, getPopupContainer, size, rootPrefixCls } = useConfigInject('picker', props);
                const pickerRef = ref();
                expose({
                    focus: () => {
                        pickerRef.value?.focus();
                    },
                    blur: () => {
                        pickerRef.value?.blur();
                    },
                });
                const maybeToString = (date) => {
                    return props.valueFormat ? generateConfig.toString(date, props.valueFormat) : date;
                };
                const onChange = (date, dateString) => {
                    const value = maybeToString(date);
                    emit('update:value', value);
                    emit('change', value, dateString);
                    formItemContext.onFieldChange();
                };
                const onOpenChange = (open) => {
                    emit('update:open', open);
                    emit('openChange', open);
                };
                const onFocus = (e) => {
                    emit('focus', e);
                };
                const onBlur = (e) => {
                    emit('blur', e);
                    formItemContext.onFieldBlur();
                };
                const onPanelChange = (date, mode) => {
                    const value = maybeToString(date);
                    emit('panelChange', value, mode);
                };
                const onOk = (date) => {
                    const value = maybeToString(date);
                    emit('ok', value);
                };
                const [contextLocale] = useLocaleReceiver('DatePicker', enUS);
                const value = computed(() => {
                    if (props.value) {
                        return props.valueFormat
                            ? generateConfig.toDate(props.value, props.valueFormat)
                            : props.value;
                    }
                    return (props.value === '' ? undefined : props.value);
                });
                const defaultValue = computed(() => {
                    if (props.defaultValue) {
                        return props.valueFormat
                            ? generateConfig.toDate(props.defaultValue, props.valueFormat)
                            : props.defaultValue;
                    }
                    return (props.defaultValue === '' ? undefined : props.defaultValue);
                });
                const defaultPickerValue = computed(() => {
                    if (props.defaultPickerValue) {
                        return props.valueFormat
                            ? generateConfig.toDate(props.defaultPickerValue, props.valueFormat)
                            : props.defaultPickerValue;
                    }
                    return (props.defaultPickerValue === '' ? undefined : props.defaultPickerValue);
                });
                return () => {
                    const locale = { ...contextLocale.value, ...props.locale };
                    const p = { ...props, ...attrs };
                    const { bordered = true, placeholder, suffixIcon = slots.suffixIcon?.(), showToday = true, transitionName, allowClear = true, dateRender = slots.dateRender, renderExtraFooter = slots.renderExtraFooter, monthCellRender = slots.monthCellRender ||
                        props.monthCellContentRender ||
                        slots.monthCellContentRender, clearIcon = slots.clearIcon?.(), id = formItemContext.id.value, ...restProps } = p;
                    const showTime = p.showTime === '' ? true : p.showTime;
                    const { format } = p;
                    let additionalOverrideProps = {};
                    if (picker) {
                        additionalOverrideProps.picker = picker;
                    }
                    const mergedPicker = picker || p.picker || 'date';
                    additionalOverrideProps = {
                        ...additionalOverrideProps,
                        ...(showTime
                            ? getTimeProps({
                                format,
                                picker: mergedPicker,
                                ...(typeof showTime === 'object' ? showTime : {}),
                            })
                            : {}),
                        ...(mergedPicker === 'time'
                            ? getTimeProps({ format, ...restProps, picker: mergedPicker })
                            : {}),
                    };
                    const pre = prefixCls.value;
                    return (<RCPicker monthCellRender={monthCellRender} dateRender={dateRender} renderExtraFooter={renderExtraFooter} ref={pickerRef} placeholder={getPlaceholder(mergedPicker, locale, placeholder)} suffixIcon={suffixIcon ||
                            (mergedPicker === 'time' ? <ClockCircleOutlined /> : <CalendarOutlined />)} clearIcon={clearIcon || <CloseCircleFilled />} allowClear={allowClear} transitionName={transitionName || `${rootPrefixCls.value}-slide-up`} {...restProps} {...additionalOverrideProps} id={id} picker={mergedPicker} value={value.value} defaultValue={defaultValue.value} defaultPickerValue={defaultPickerValue.value} showToday={showToday} locale={locale.lang} class={classNames({
                            [`${pre}-${size.value}`]: size.value,
                            [`${pre}-borderless`]: !bordered,
                        }, attrs.class)} prefixCls={pre} getPopupContainer={attrs.getCalendarContainer || getPopupContainer.value} generateConfig={generateConfig} prevIcon={slots.prevIcon?.() || <span class={`${pre}-prev-icon`}/>} nextIcon={slots.nextIcon?.() || <span class={`${pre}-next-icon`}/>} superPrevIcon={slots.superPrevIcon?.() || <span class={`${pre}-super-prev-icon`}/>} superNextIcon={slots.superNextIcon?.() || <span class={`${pre}-super-next-icon`}/>} components={Components} direction={direction.value} onChange={onChange} onOpenChange={onOpenChange} onFocus={onFocus} onBlur={onBlur} onPanelChange={onPanelChange} onOk={onOk}/>);
                };
            },
        });
    }
    const DatePicker = getPicker(undefined, 'ADatePicker');
    const WeekPicker = getPicker('week', 'AWeekPicker');
    const MonthPicker = getPicker('month', 'AMonthPicker');
    const YearPicker = getPicker('year', 'AYearPicker');
    const TimePicker = getPicker('time', 'TimePicker'); // 给独立组件 TimePicker 使用，此处名称不用更改
    const QuarterPicker = getPicker('quarter', 'AQuarterPicker');
    return {
        DatePicker,
        WeekPicker,
        MonthPicker,
        YearPicker,
        TimePicker,
        QuarterPicker,
    };
}
